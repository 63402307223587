<template>
  <div>
    <portal selector="#portal-target">
      <connect-modal :show="showPopup" width="600px" bkcolor="#F7F7F7" class="configure-dialog">
        <div class="relative">
          <save-changes @on-save-changes="onSaveChanges" @on-cancel-changes="showPopup = false" class="configure-dialog-save-changes"></save-changes>
          <div class="flex flex-row configure-dialog-modal-title">
            <div>
              <h3>{{ params.colDef.headerName }}</h3>
            </div>
          </div>
          <display-options id="crdo" v-if="dialog && dialogId" :dialogId="dialogId" :infoImg="infoImg"></display-options>
        </div>
      </connect-modal>
    </portal>

    <div>
      <vs-button
        v-if="params.value === true"
        class="mr-2"
        color="dark"
        type="line"
        size="large"
        icon-pack="material-icons"
        icon="videocam"
        @click="showDisplayOption"
      ></vs-button>
      <vs-button
        v-else
        class="mr-2"
        color="dark"
        type="line"
        size="large"
        icon-pack="material-icons"
        icon="photo_camera"
        @click="showDisplayOption"
      ></vs-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Portal } from '@linusborg/vue-simple-portal'
import ConnectModal from '../../../components/ConnectModal.vue'

import DisplayOptions from '../../../components/dialog/DisplayOptions.vue'
import SaveChanges from './SaveChanges.vue'

export default {
  name: 'CellRendererDisplayOption',
  components: {
    Portal,
    ConnectModal,
    DisplayOptions,
    SaveChanges
  },
  data() {
    return {
      showPopup: false,
      dialogId: null,
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog'
    })
  },

  methods: {
    showDisplayOption() {
      const dialog = { ...this.params.data }
      this.$store.commit('UPDATE_DIALOG', dialog)
      this.dialogId = dialog.dialogId
      this.showPopup = true
    },
    onSaveChanges() {
      try {
        this.$serverBus.$emit('ab-testing-update-display-option', {
          newValue: this.dialog,
          data: this.params.data
        })
        this.showPopup = false
      } catch (error) {
        /* eslint-disable no-console */
        console.error(error)
      }
    }
  },
  mounted() {
    this.$serverBus.$on('update-dialog-show-video', (show) => {
      this.$store.commit('UPDATE_DIALOG', { ...this.dialog, showVideo: show, showDefaultMedia: true })
    })

    this.$serverBus.$on('toggle-use-one-media-for-all-steps', (val) => {
      this.$store.commit('UPDATE_DIALOG', {
        ...this.dialog,
        useOneMediaForAllSteps: val
      })
    })

    this.$serverBus.$on('update-dialog-show-media', (show) => {
      this.$store.commit('UPDATE_DIALOG', { ...this.dialog, showDefaultMedia: show })
    })

    this.$serverBus.$on('update-dialog-type', (dialog) => {
      this.$store.commit('UPDATE_DIALOG', { ...this.dialog, ...dialog })
    })
    this.$serverBus.$on('toggle-use-new-button-plus-text-style', (type) => {
      this.$store.commit('UPDATE_DIALOG', { ...this.dialog, type: 'button', useNewButtonPlusTextStyle: type, isSmallButtonPreviewVisible: false })
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('update-dialog-show-video')
    this.$serverBus.$off('toggle-use-one-media-for-all-steps')
    this.$serverBus.$off('update-dialog-show-media')
    this.$serverBus.$off('update-dialog-type')
    this.$serverBus.$off('toggle-use-new-button-plus-text-style')
  }
}
</script>

<style lang="scss">
.check-circle-icon {
  position: absolute;
  top: 15px;
  width: 50px;
  height: 50px;
  right: 20px;
  z-index: 999;
  border-radius: 6px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}
.check-circle-icon:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  background-color: #026e02 !important;
  opacity: 0.6;
}
@import '../../pages/dialog/configure-dialog.scss';
</style>
