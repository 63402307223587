<template>
  <div>
    <portal selector="#portal-target">
      <connect-modal :show="showPopup" width="calc(70vw)" bkcolor="#F7F7F7" class="configure-dialog">
        <scroll-view style="height: calc(85vh)">
          <div class="relative">
            <save-changes @on-save-changes="onSaveChanges" @on-cancel-changes="showPopup = false" class="configure-dialog-save-changes"></save-changes>
            <div class="flex flex-row configure-dialog-modal-title">
              <div>
                <h3>{{ params.colDef.headerName }}</h3>
              </div>
            </div>
            <session-end-screen v-if="dialog" :infoImg="infoImg"></session-end-screen>
          </div>
        </scroll-view>
      </connect-modal>
    </portal>
    <div>
      <vs-button class="mr-2" color="dark" type="line" size="large" icon-pack="material-icons" icon="star_border" @click="showSessionEndScreen"></vs-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Portal } from '@linusborg/vue-simple-portal'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import ConnectModal from '../../../components/ConnectModal.vue'
import SessionEndScreen from '../../../components/dialog/SessionEndScreen.vue'
import SaveChanges from './SaveChanges.vue'
export default {
  components: {
    ScrollView,
    Portal,
    ConnectModal,
    SessionEndScreen,
    SaveChanges
  },
  data() {
    return {
      showPopup: false,
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog'
    })
  },
  methods: {
    translate(code) {
      return this.$i18n.t(code)
    },
    onSaveChanges() {
      try {
        this.$serverBus.$emit('ab-testing-update-session-end-screen', {
          field: this.params.colDef.field,
          data: this.params.data,
          newValue: this.dialog
        })
        this.showPopup = false
      } catch (error) {
        /* eslint-disable no-console */
        console.error(error)
      }
    },
    showSessionEndScreen() {
      const dialog = { ...this.params.data }
      if (dialog && typeof dialog.sessionEndScreen === 'undefined') {
        dialog.sessionEndScreen = {
          titleClosingMessage: this.translate('vue.sessionEndScreenDefaultTitle'),
          copyClosingMessage: this.translate('vue.sessionEndScreenDefaultCopy'),
          backgroundColor: '#3B86F7',
          fontColor: '#FFFFFF'
        }
      }
      if (dialog && typeof dialog.isShowFeedbackForm === 'undefined') {
        dialog.isShowFeedbackForm = false
      }

      if (dialog && typeof dialog.isSendFeedbackViaMessage === 'undefined') {
        dialog.isSendFeedbackViaMessage = false
      }

      if (dialog && typeof dialog.feedbackFormQuestionFirst === 'undefined') {
        dialog.feedbackFormQuestionFirst = ''
      }

      if (dialog && typeof dialog.feedbackFormQuestionSecond === 'undefined') {
        dialog.feedbackFormQuestionSecond = ''
      }

      if (dialog && typeof dialog.feedbackFormQuestionThird === 'undefined') {
        dialog.feedbackFormQuestionThird = ''
      }

      this.$store.commit('UPDATE_DIALOG', dialog)
      this.showPopup = true
    }
  }
}
</script>
<style lang="scss">
@import '../../pages/dialog/configure-dialog.scss';
</style>
