var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configure-dialog-step-four" }, [
    _c("div", { staticClass: "flex-box-column-start" }, [
      _c(
        "div",
        { staticClass: "vx-row w-full" },
        [
          _c(
            "vs-col",
            {
              staticClass: "flex-box-row-start w-full",
              attrs: { "vs-w": "12" },
            },
            [
              _c("div", { staticClass: "headingfont mr-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.welcomeMessageSetupHeader"))),
              ]),
              _c(
                "vx-tooltip",
                {
                  attrs: {
                    text: _vm.$t("info.welcomeMessageSetupHeader"),
                    position: "top",
                  },
                },
                [_c("img", { attrs: { src: _vm.infoImg } })]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "mt-4 flex w-full" }, [
      _c("div", { staticClass: "mb-2" }, [
        _vm._v(_vm._s(_vm.$t("vue.welcomeMessageLabel"))),
      ]),
    ]),
    _c("div", { staticClass: "flex-view" }, [
      _c("div", { staticClass: "mr-5 flex flex-column" }, [
        _c(
          "div",
          [
            _c("quill-editor", {
              ref: "quillEditorA",
              attrs: {
                value: _vm.dialog.waitingRoomMessage,
                options: _vm.editorOption,
              },
              on: { input: _vm.updateWaitingRoomMessage },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }