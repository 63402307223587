var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "connect-modal",
        {
          staticClass: "configure-dialog preview",
          attrs: { show: _vm.showPreview, bkcolor: "#F7F7F7" },
        },
        [
          _c(
            "div",
            { staticClass: "relative" },
            [
              _c(
                "div",
                { staticClass: "flex flex-row configure-dialog-modal-title" },
                [_c("div", [_c("h3", [_vm._v(_vm._s(_vm.$t("vue.preview")))])])]
              ),
              _c(
                "div",
                {
                  staticClass: "configure-dialog-close-button",
                  attrs: { disabled: "" },
                  on: {
                    click: function ($event) {
                      _vm.showPreview = false
                    },
                  },
                },
                [
                  _c("close-circle-icon", {
                    staticClass: "pa-bk-secondary",
                    attrs: { "show-circle": false, width: 14, height: 14 },
                  }),
                ],
                1
              ),
              _vm.previewDialog && _vm.previewDialogId
                ? _c("preview-dialog", {
                    key: _vm.previewDialogKey,
                    attrs: {
                      dialog: _vm.previewDialog,
                      embedVideoURL: _vm.embedVideoURL,
                      smallButtonImage: _vm.smallButtonImage,
                      imageURL: _vm.imageURL,
                      videoKey: _vm.videoKey,
                      buttonContainerMinWidth: _vm.buttonContainerMinWidth,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full mb-2" },
          [
            _c(
              "vs-button",
              {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-plus",
                  disabled: !_vm.HAS_ABTESTING_ACCESS,
                  type: "filled",
                },
                on: { click: _vm.onAddTest },
              },
              [_vm._v(_vm._s(_vm.$t("vue.addVersion")))]
            ),
          ],
          1
        ),
      ]),
      _vm.abtests && _vm.abtests.length > 0
        ? _c("ag-grid-vue", {
            key: _vm.updateKey,
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            attrs: {
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              columnTypes: _vm.columnTypes,
              rowData: _vm.abtests,
              rowSelection: "multiple",
              colResizeDefault: "shift",
              pagination: true,
              paginationPageSize: _vm.paginationPageSize,
              suppressPaginationPanel: true,
              suppressPropertyNamesCheck: true,
              enableRtl: _vm.$vs.rtl,
            },
            on: { "cell-clicked": _vm.onCellClicked },
          })
        : _vm._e(),
      _vm.abtests && _vm.abtests.length > 0
        ? _c("vs-pagination", {
            attrs: { total: _vm.totalPages, max: 7 },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }