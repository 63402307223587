<template>
  <vs-chip v-if="params && params.value && params.value.length > 0" class="ag-grid-cell-chip" :color="chipColor(params.value)">
    <span>{{ dialogType(params.value) }}</span>
  </vs-chip>
</template>

<script>
export default {
  name: 'CellRendererDialogType',
  computed: {
    chipColor() {
      return (value) => {
        if (value === 'button') return 'gray'
        else return 'black'
      }
    }
  },
  methods: {
    translate(code) {
      return this.$i18n.t(code)
    },
    dialogType(type) {
      let dialogType = type
      switch (type) {
        case 'button':
          dialogType = this.translate('vue.button-text')
          break

        case 'button-card':
          dialogType = this.translate('vue.button')
          break
        case 'popup':
          dialogType = this.translate('vue.popup')
          break
      }
      return dialogType
    }
  }
}
</script>

<style lang="scss" scoped>
.ag-grid-cell-chip {
  cursor: pointer;
  &.vs-chip-success {
    background: rgba(var(--vs-success), 0.15);
    color: rgba(var(--vs-success), 1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning), 0.15);
    color: rgba(var(--vs-warning), 1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger), 0.15);
    color: rgba(var(--vs-danger), 1) !important;
    font-weight: 500;
  }
}
</style>
