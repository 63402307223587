<template>
  <div>
    <connect-modal :show="showPreview" bkcolor="#F7F7F7" class="configure-dialog preview">
      <div class="relative">
        <div class="flex flex-row configure-dialog-modal-title">
          <div>
            <h3>{{ $t('vue.preview') }}</h3>
          </div>
        </div>
        <div disabled class="configure-dialog-close-button" @click="showPreview = false">
          <close-circle-icon class="pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
        </div>

        <preview-dialog
          v-if="previewDialog && previewDialogId"
          :dialog="previewDialog"
          :embedVideoURL="embedVideoURL"
          :smallButtonImage="smallButtonImage"
          :imageURL="imageURL"
          :videoKey="videoKey"
          :buttonContainerMinWidth="buttonContainerMinWidth"
          :key="previewDialogKey"
        />
      </div>
    </connect-modal>

    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <vs-button icon-pack="feather" icon="icon-plus" :disabled="!HAS_ABTESTING_ACCESS" type="filled" @click="onAddTest">{{
          $t('vue.addVersion')
        }}</vs-button>
      </div>
    </div>
    <!-- AgGrid Table -->
    <ag-grid-vue
      v-if="abtests && abtests.length > 0"
      :key="updateKey"
      ref="agGridTable"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :columnTypes="columnTypes"
      :rowData="abtests"
      rowSelection="multiple"
      colResizeDefault="shift"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :suppressPropertyNamesCheck="true"
      :enableRtl="$vs.rtl"
      @cell-clicked="onCellClicked"
    >
    </ag-grid-vue>

    <vs-pagination v-if="abtests && abtests.length > 0" :total="totalPages" :max="7" v-model="currentPage" />
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

import { mapGetters, mapMutations, mapActions } from 'vuex'
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

import ConnectModal from '../../components/ConnectModal.vue'
import CloseCircleIcon from '../../components/icons/CloseCircleIcon.vue'

// Cell Renderer
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererDialogType from './cell-renderer/CellRendererDialogType.vue'
import CellRendererDisplayOption from './cell-renderer/CellRendererDisplayOption.vue'
import CellRendererText from './cell-renderer/CellRendererText.vue'
import CellRendererColor from './cell-renderer/CellRendererColor.vue'
import CellRendererTextAndDialogType from './cell-renderer/CellRendererTextAndDialogType.vue'
import CellRendererWelcomeMessage from './cell-renderer/CellRendererWelcomeMessage.vue'
import CellRendererSessionEndScreen from './cell-renderer/CellRendererSessionEndScreen.vue'
import CellRendererContactForm from './cell-renderer/CellRendererContactForm.vue'

import PreviewDialog from '../../components/dialog/PreviewDialog.vue'

export default {
  components: {
    ConnectModal,
    // eslint-disable-next-line
    CloseCircleIcon,
    // eslint-disable-next-line
    AgGridVue,
    // eslint-disable-next-line
    CellRendererActions,
    // eslint-disable-next-line
    CellRendererDialogType,
    // eslint-disable-next-line
    CellRendererDisplayOption,
    // eslint-disable-next-line
    CellRendererText,
    // eslint-disable-next-line
    CellRendererColor,
    // eslint-disable-next-line
    PreviewDialog,
    // eslint-disable-next-line
    CellRendererContactForm,
    // eslint-disable-next-line
    CellRendererSessionEndScreen,
    // eslint-disable-next-line
    CellRendererWelcomeMessage,
    // eslint-disable-next-line
    CellRendererTextAndDialogType
  },
  filters: {
    upper(value) {
      if (!value) return ''
      return value.toString().toUpperCase()
    }
  },
  data() {
    return {
      updateKey: Math.random().toString(36).substring(2, 15),
      defaultSloganTitle: "Let's Connect!",
      showPreview: false,
      previewDialog: null,
      previewDialogId: null,
      dialogId: this.$route.params.id,
      tests: [],
      conversionRates: [],
      // AgGrid
      gridApi: null,
      gridOptions: {
        suppressPropertyNamesCheck: true
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        autoHeight: true,
        suppressMenu: true
      },
      columnTypes: {
        textColumn: { editable: false },
        textAreaColumn: { editable: false },
        textColorColumn: { editable: false },
        backgroundColorColum: { editable: false },
        numberColumn: { editable: false }
      },
      unsubscribeTest: null,
      defaultImageURL: require('@/assets/images/dialog/contact-form-background.jpeg'),
      defaultSmallButtonImage: require('@/assets/images/dialog/default-small-button-image.svg'),
      videoKey: Math.random().toString(36).substring(2, 15),
      previewDialogKey: Math.random().toString(36).substring(2, 15),
      buttonContainerMinWidth: 0
    }
  },
  watch: {
    previewDialogId() {
      this.videoKey = Math.random().toString(36).substring(2, 15)
    }
  },

  computed: {
    ...mapGetters({
      company: 'company',
      hasWhiteLabel: 'hasWhiteLabel',
      abtests: 'abtests',
      dialogAbTests: 'mAbTests/dialogAbTests'
    }),
    HAS_ABTESTING_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('ab-testing') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    smallButtonImage() {
      if (this.previewDialog) {
        return this.previewDialog.smallButtonImage ? this.previewDialog.smallButtonImage : this.defaultSmallButtonImage
      }
      return this.defaultSmallButtonImage
    },
    defaultVersionName() {
      return this.translate('vue.newVersion', 'en')
    },
    embedVideoURL() {
      if (!this.previewDialog) {
        return this.defaultVideoURL
      }
      let videoURL = this.previewDialog.transcodedURL || this.previewDialog.videoURL
      videoURL = videoURL || this.defaultVideoURL
      return videoURL
    },
    imageURL() {
      if (!this.previewDialog) {
        return this.defaultImageURL
      }
      return this.previewDialog.imageURL ? this.previewDialog.imageURL : this.defaultImageURL
    },
    defaultVideoURL() {
      return '/Mann-Winken-SD.mp4'
    },

    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set(val) {
        if (this.gridApi) this.gridApi.paginationGoToPage(val - 1)
      }
    },
    columnDefs() {
      return [
        {
          headerName: this.translate('vue.action'),
          field: 'id',
          cellRendererFramework: 'CellRendererActions',
          width: 280,
          cellStyle: this.setCellStyle
        },
        {
          headerName: this.translate('vue.dialogName'),
          field: 'name',
          filter: true,
          cellRendererFramework: 'CellRendererText',
          type: 'textColumn',
          cellStyle: this.setCellStyle
        },
        {
          headerName: this.translate('vue.dialogType'),
          field: 'type',
          cellRendererFramework: 'CellRendererDialogType',
          cellStyle: (params) => {
            if (params.data.default) {
              return { marginTop: '21px' }
            }
            return null
          }
        },
        {
          headerName: this.translate('vue.conversionRate'),
          field: 'conversion_rate',
          cellStyle: this.setCellStyle
        },
        {
          headerName: this.translate('vue.displayOptions'),
          field: 'showVideo',
          cellRendererFramework: 'CellRendererDisplayOption',
          cellStyle: this.setCellStyle
        },
        {
          headerName: this.translate('vue.textDialogtype'),
          filter: false,
          cellRendererFramework: 'CellRendererTextAndDialogType',
          cellStyle: this.setCellStyle
        },
        {
          headerName: this.translate('vue.welcomeMessage'),
          field: 'waitingRoomMessage',
          filter: false,
          cellRendererFramework: 'CellRendererWelcomeMessage',
          cellStyle: this.setCellStyle
        },
        {
          headerName: this.translate('vue.endScreen'),
          field: 'sessionEndScreen',
          filter: false,
          cellRendererFramework: 'CellRendererSessionEndScreen',
          cellStyle: this.setCellStyle
        },
        {
          headerName: this.translate('vue.contactForm'),
          field: 'contactForm',
          filter: false,
          cellRendererFramework: 'CellRendererContactForm',
          cellStyle: this.setCellStyle
        }
      ]
    }
  },
  async created() {
    const vm = this

    vm.$serverBus.$on('ab-testing-duplicate', async (id) => {
      vm.$vs.loading()
      await vm.duplicate(id)
      vm.$vs.loading.close()
    })

    vm.$serverBus.$on('ab-testing-preview', async (id) => {
      await vm.preview(id)
    })

    vm.$serverBus.$on('ab-testing-delete-test', async (id) => {
      vm.$vs.loading()
      const test = vm.abtests.filter((x) => x.id === id)[0]
      if (vm.abtests.filter((x) => x.enabled).length <= 1 && test.enabled) {
        vm.$vs.notify({
          time: 4000,
          title: vm.$i18n.t('vue.error'),
          text: vm.$i18n.t('vue.atleastOneTestEnabled'),
          color: 'warning',
          iconPack: 'feather',
          position: 'top-center',
          icon: 'icon-alert-circle'
        })
      } else {
        await vm.$db.collection('dialogs').doc(vm.dialogId).collection('ab-tests').doc(id).delete()
        const itemIndex = vm.abtests.findIndex((p) => p.id === id)
        vm.abtests.splice(itemIndex, 1)
        vm.updateDialogAbTests({ dialogId: vm.dialogId, abtests: vm.abtests })
      }
      setTimeout(() => {
        vm.$vs.loading.close()
      }, 500)
    })

    vm.$serverBus.$on('ab-testing-change-status', async (id) => {
      const test = vm.abtests.filter((x) => x.id === id)[0]
      if (test.enabled && vm.abtests.filter((x) => x.enabled).length <= 1) {
        vm.$vs.notify({
          time: 4000,
          title: vm.$i18n.t('vue.error'),
          text: vm.$i18n.t('vue.atleastOneTestEnabled'),
          color: 'warning',
          iconPack: 'feather',
          position: 'top-center',
          icon: 'icon-alert-circle'
        })
      } else {
        test.enabled = !test.enabled
        await vm.toggleStatus(test.id, test.enabled)
        if (vm.gridOptions && vm.gridOptions.api) {
          const rowNode = vm.gridOptions.api.getRowNode(test.id)
          if (rowNode) {
            rowNode.setData(test)
          }
          vm.gridOptions.api.refreshCells()
        }
        vm.updateDialogAbTests({ dialogId: vm.dialogId, abtests: vm.abtests })
      }
    })

    vm.$serverBus.$on('ab-testing-update-text', async (t) => {
      t.data[t.field] = t.newValue
      await vm.updateTextField(t.data.id, t.data.default, t.field, t.newValue)
      if (vm.gridOptions && vm.gridOptions.api) {
        const rowNode = vm.gridOptions.api.getRowNode(t.data.id)
        if (rowNode) {
          rowNode.setData(t.data)
        }
        vm.gridOptions.api.refreshCells()
      }
    })

    vm.$serverBus.$on('ab-testing-update-color', async (t) => {
      t.data[t.field] = t.newValue
      await vm.updateTextField(t.data.id, t.data.default, t.field, t.newValue)
      if (vm.gridOptions && vm.gridOptions.api) {
        const rowNode = vm.gridOptions.api.getRowNode(t.data.id)
        if (rowNode) {
          rowNode.setData(t.data)
        }
        vm.gridOptions.api.refreshCells()
      }
    })
    vm.$serverBus.$emit('on-own-media-delete', async (t) => {
      await vm.updateDisplayOption(t.data.id, t.data.default, t.newValue)
      if (vm.gridOptions && vm.gridOptions.api) {
        const rowNode = vm.gridOptions.api.getRowNode(t.data.id)
        if (rowNode) {
          rowNode.setData(t.data)
        }
        vm.gridOptions.api.refreshCells()
      }
    })

    vm.$serverBus.$on('on-own-media-save', async (t) => {
      await vm.updateDisplayOption(t.data.id, t.data.default, t.newValue)
      if (vm.gridOptions && vm.gridOptions.api) {
        const rowNode = vm.gridOptions.api.getRowNode(t.data.id)
        if (rowNode) {
          rowNode.setData(t.data)
        }
        vm.gridOptions.api.refreshCells()
      }
    })

    vm.$serverBus.$on('ab-testing-update-display-option', async (t) => {
      await vm.updateDisplayOption(t.data.id, t.data.default, t.newValue)
      if (vm.gridOptions && vm.gridOptions.api) {
        const rowNode = vm.gridOptions.api.getRowNode(t.data.id)
        if (rowNode) {
          rowNode.setData(t.data)
        }
        vm.gridOptions.api.refreshCells()
      }
      this.$serverBus.$emit('update-button-preview')
    })

    vm.$serverBus.$on('ab-testing-update-contact-form', async (t) => {
      t.data[t.field] = t.newValue
      await vm.updateObject(t.data.id, t.data.default, t.field, t.newValue)
      if (vm.gridOptions && vm.gridOptions.api) {
        const rowNode = vm.gridOptions.api.getRowNode(t.data.id)
        if (rowNode) {
          rowNode.setData(t.data)
        }
        vm.gridOptions.api.refreshCells()
      }
    })

    vm.$serverBus.$on('ab-testing-update-session-end-screen', async (t) => {
      const updatedFields = {}
      updatedFields.sessionEndScreen = t.newValue.sessionEndScreen
      updatedFields.isShowFeedbackForm = t.newValue.isShowFeedbackForm
      updatedFields.isSendFeedbackViaMessage = t.newValue.isSendFeedbackViaMessage
      updatedFields.feedbackFormQuestionFirst = t.newValue.feedbackFormQuestionFirst
      updatedFields.feedbackFormQuestionSecond = t.newValue.feedbackFormQuestionSecond
      updatedFields.feedbackFormQuestionThird = t.newValue.feedbackFormQuestionThird

      await Object.keys(updatedFields).forEach(async (key) => (updatedFields[key] === null || updatedFields[key] === undefined) && delete updatedFields[key])

      for (const prop in updatedFields) {
        t.data[prop] = updatedFields[prop]
      }
      await vm.updateEndScreen(t.data.id, t.data.default, updatedFields)

      if (vm.gridOptions && vm.gridOptions.api) {
        const rowNode = vm.gridOptions.api.getRowNode(t.data.id)
        if (rowNode) {
          rowNode.setData(t.data)
        }
        vm.gridOptions.api.refreshCells()
      }
    })

    vm.$serverBus.$on('ab-testing-update-waiting-room', async (t) => {
      const updatedFields = {}
      updatedFields.waitingRoomContentChatMessage = t.newValue.waitingRoomContentChatMessage
      updatedFields.waitingRoomMessage = t.newValue.waitingRoomMessage
      updatedFields.waitingRoomBackground = t.newValue.waitingRoomBackground

      await Object.keys(updatedFields).forEach(async (key) => (updatedFields[key] === null || updatedFields[key] === undefined) && delete updatedFields[key])

      for (const prop in updatedFields) {
        t.data[prop] = updatedFields[prop]
      }
      await vm.updateWaitingRoomScreen(t.data.id, t.data.default, updatedFields)

      if (vm.gridOptions && vm.gridOptions.api) {
        const rowNode = vm.gridOptions.api.getRowNode(t.data.id)
        if (rowNode) {
          rowNode.setData(t.data)
        }
        vm.gridOptions.api.refreshCells()
      }
    })

    vm.$serverBus.$on('ab-testing-update-text-dialog-type', async (t) => {
      const updatedFields = {}
      updatedFields.visibleTimeout = t.newValue.visibleTimeout
      updatedFields.contactFormTimeout = t.newValue.contactFormTimeout
      updatedFields.text = t.newValue.text
      updatedFields.confirmButtonText = t.newValue.confirmButtonText
      updatedFields.textStep2 = t.newValue.textStep2
      updatedFields.isMoreInformationEnabled = t.newValue.isMoreInformationEnabled
      updatedFields.moreInformationLink = t.newValue.moreInformationLink
      updatedFields.buttonPlusTextNewMediaIconColor = t.newValue.buttonPlusTextNewMediaIconColor
      updatedFields.step2ButtonColor = t.newValue.step2ButtonColor
      updatedFields.step2TextColor = t.newValue.step2TextColor
      updatedFields.step2IconColor = t.newValue.step2IconColor
      updatedFields.confirmButtonColor = t.newValue.confirmButtonColor
      updatedFields.confirmButtonTextColor = t.newValue.confirmButtonTextColor

      if (!this.hasWhiteLabel) {
        updatedFields.sloganTitle = this.defaultSloganTitle
      } else {
        updatedFields.sloganTitle = t.newValue.sloganTitle
      }

      await Object.keys(updatedFields).forEach(async (key) => (updatedFields[key] === null || updatedFields[key] === undefined) && delete updatedFields[key])
      for (const prop in updatedFields) {
        t.data[prop] = updatedFields[prop]
      }

      await vm.updateTextAndDialogType(t.data.id, t.data.default, updatedFields)
      if (vm.gridOptions && vm.gridOptions.api) {
        const rowNode = vm.gridOptions.api.getRowNode(t.data.id)
        if (rowNode) {
          rowNode.setData(t.data)
        }
        vm.gridOptions.api.refreshCells()
      }
    })

    vm.gridOptions.getRowNodeId = function (data) {
      return data.id
    }

    vm.gridOptions.rowClassRules = {
      'default-dialog': 'data.default === true'
    }
  },
  async mounted() {
    const vm = this
    await vm.$vs.loading()

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`
    }

    const dialogAbTests = this.dialogAbTests.find((x) => x.dialogId === vm.dialogId)
    if (!dialogAbTests) {
      await vm.getAllTests()
    } else {
      vm.updateABTests([...dialogAbTests.abtests])
    }

    const getDialogConversionRate = this.$functions.httpsCallable('getDialogConversionRate')
    getDialogConversionRate({ dialogId: this.$route.params.id })
      .then((res) => {
        if (res && res.data && res.data.dialogs && res.data.dialogs.length > 0) {
          vm.conversionRates = res.data.dialogs
          vm.updateConversionRates(vm.conversionRates)
        }
      })
      .catch((e) => {
        /* eslint-disable no-console */
        console.log(e.message)
      })

    this.gridApi = this.gridOptions.api

    await vm.$vs.loading.close()

    vm.windowResize()

    const supportsOrientationChange = 'onorientationchange' in window
    const orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize'
    if (orientationEvent === 'orientationchange') {
      window.addEventListener(
        orientationEvent,
        function () {
          vm.buttonContainerMinWidth = 0
          setTimeout(async () => {
            vm.windowResize()
          }, 1000)
        },
        false
      )
    }

    window.addEventListener('resize', vm.windowResize)
  },
  beforeDestroy() {
    const vm = this
    if (vm.unsubscribeTest) {
      vm.unsubscribeTest()
    }
    vm.$serverBus.$off('ab-testing-duplicate')
    vm.$serverBus.$off('ab-testing-preview')
    vm.$serverBus.$off('ab-testing-delete-test')
    vm.$serverBus.$off('ab-testing-change-status')
    vm.$serverBus.$off('ab-testing-update-text')
    vm.$serverBus.$off('ab-testing-update-color')
    vm.$serverBus.$off('ab-testing-update-display-option')
    vm.$serverBus.$off('ab-testing-update-text-dialog-type')
    vm.$serverBus.$off('ab-testing-update-waiting-room')
    vm.$serverBus.$off('ab-testing-update-session-end-screen')
    vm.$serverBus.$off('ab-testing-update-contact-form')

    vm.$serverBus.$off('on-own-media-save')
    vm.$serverBus.$off('on-own-media-delete')
  },
  methods: {
    ...mapActions({
      updateDialogAbTests: 'mAbTests/updateDialogAbTests'
    }),
    ...mapMutations({
      updateABTests: 'UPDATE_ABTESTS'
    }),

    updateConversionRates(conversionRates) {
      if (this.abtests && this.abtests.length > 0) {
        for (const abtest of this.abtests) {
          const foundConversionRate = conversionRates.find((x) => x.test_id === abtest.testId)
          abtest.conversion_rate = foundConversionRate ? `${foundConversionRate.conversion_rate} %` : `${0.0} %`
        }
      }
      this.updateKey = Math.random().toString(36).substring(2, 15)

      const popups = document.querySelectorAll('.abtest-popup')
      popups.forEach((popup) => {
        popup.remove()
      })
    },

    setCellStyle(params) {
      const styles = { display: 'flex', alignItems: 'flex-end' }

      if (params.data.default) {
        return styles
      }

      return null
    },

    windowResize() {
      let w = window.innerWidth
      //let h = window.innerHeight
      if (window.innerWidth !== undefined && window.innerHeight !== undefined) {
        w = window.innerWidth
        //h = window.innerHeight
      } else {
        w = document.documentElement.clientWidth
        //h = document.documentElement.clientHeight
      }
      let newW = w * 0.5833333333333333
      this.leftScreenVSWidth = 7
      this.rightScreenVSWidth = 5
      if (w > 1200) {
        newW = newW - 280
        this.buttonContainerMinWidth = newW
      } else if (w >= 769 && w < 990) {
        this.buttonContainerMinWidth = w - 200
      } else if (w > 768 && w < 1200) {
        newW = newW - 120
        this.buttonContainerMinWidth = newW
      } else {
        this.buttonContainerMinWidth = w - 200
      }
    },
    translate(code, language) {
      if (language) {
        return this.$i18n.t(code, language)
      }

      return this.$i18n.t(code)
    },
    async duplicate(id) {
      const vm = this
      const data = { ...vm.abtests.filter((x) => x.id === id)[0] }
      delete data.default
      delete data.info
      delete data.id
      data.created = new Date()
      data.enabled = false
      data.conversion_rate = ''
      data.copied_from = id
      const copyNumber = vm.abtests.filter((x) => x.copied_from === id).length + 1
      data.name = `${data.name} ${vm.translate('vue.copy2')} - ${copyNumber}`

      if (data.videoURL || data.transcodedURL) {
        data.duplicate_video = true
      }
      if (data.imageURL) {
        data.duplicate_image = true
      }

      if (typeof data.isMoreInformationEnabled === 'undefined') {
        data.isMoreInformationEnabled = false
        data.moreInformationButtonText = this.translate('vue.moreInformation', 'en')
      }
      if (typeof data.textStep2 === 'undefined') {
        data.textStep2 = this.translate('vue.textStep2DefaultText', 'en')
      }

      if (typeof data.buttonPlusTextNewMediaIconColor === 'undefined') {
        data.buttonPlusTextNewMediaIconColor = '#3034F7'
      }

      if (typeof data.step2ButtonColor === 'undefined') {
        data.step2ButtonColor = '#3B86F7'
        data.step2TextColor = '#ffffff'
      }

      if (!data.sloganTitle) {
        data.sloganTitle = "Let's Connect!"
      }

      if (typeof data.waitingRoomMessage === 'undefined') {
        data.waitingRoomMessage = this.translate('vue.waitingRoomDefaultMessage', 'en')
      }

      if (data && typeof data.waitingRoomContentChatMessage === 'undefined') {
        data.waitingRoomContentChatMessage = {
          backgroundColor: '#C4C4C4',
          fontColor: '#12598d'
        }
      }

      if (typeof data.sessionEndScreen === 'undefined') {
        data.sessionEndScreen = {
          titleClosingMessage: this.translate('vue.sessionEndScreenDefaultTitle', 'en'),
          copyClosingMessage: this.translate('vue.sessionEndScreenDefaultCopy', 'en'),
          backgroundColor: '#3B86F7',
          fontColor: '#FFFFFF'
        }
      }

      if (typeof data.isShowFeedbackForm === 'undefined') {
        data.isShowFeedbackForm = false
      }

      if (typeof data.isSendFeedbackViaMessage === 'undefined') {
        data.isSendFeedbackViaMessage = false
      }

      if (typeof data.feedbackFormQuestionFirst === 'undefined') {
        data.feedbackFormQuestionFirst = ''
      }

      if (typeof data.feedbackFormQuestionSecond === 'undefined') {
        data.feedbackFormQuestionSecond = ''
      }

      if (typeof data.feedbackFormQuestionThird === 'undefined') {
        data.feedbackFormQuestionThird = ''
      }

      if (typeof data.contactForm === 'undefined') {
        data.contactForm = {
          message: this.translate('vue.contactFormText', 'en'),
          backgroundColor: '#3B86F7',
          fontColor: '#FFFFFF',
          isBookingEnabled: false,
          selectedAppointment: null,
          fields: {
            name: {
              enabled: true,
              required: true
            },
            email: {
              enabled: true,
              required: true
            },
            phone: {
              enabled: true,
              required: true
            },
            message: {
              enabled: true,
              required: true
            },
            date: {
              enabled: false,
              required: false
            }
          },
          sendMessageTitle: this.translate('vue.sendMessageDefaultTitle', 'en'),
          sendMessageCopy: this.translate('vue.sendMessageDefaultCopy', 'en'),
          bookingSlotTitle: this.translate('vue.bookingSlotDefaultTitle', 'en'),
          bookingSlotCopy: this.translate('vue.bookingSlotDefaultCopy', 'en'),
          isPrivacyInformationEnabled: false,
          isDefaultContactFormInsideChat: false,
          privacyInformationHtml: ''
        }
      }

      /*
        Privacy Information added in later stage of development, so adding this check here
      */
      if (data.contactForm && typeof data.contactForm.isPrivacyInformationEnabled === 'undefined') {
        data.contactForm.isPrivacyInformationEnabled = false
        data.contactForm.privacyInformationHtml = ''
      }

      const test = await vm.$db.collection('dialogs').doc(vm.dialogId).collection('ab-tests').add(data)
      data.id = test.id
      data.testId = test.id

      vm.abtests.push({ ...data })
      vm.updateDialogAbTests({ dialogId: vm.dialogId, abtests: vm.abtests })
    },
    async preview(id) {
      this.$vs.loading()

      this.previewDialog = this.abtests.find((x) => x.id === id)
      this.previewDialogId = id

      this.showPreview = true
      this.previewDialogKey = Math.random().toString(36).substring(2, 15)

      setTimeout(() => {
        this.$vs.loading.close()
      }, 100)
    },
    async onCellClicked(cell) {
      if (cell.colDef.field === 'info') {
        await this.toggleStatus(cell.data.id, !cell.data.enabled)
      }
      if (cell.colDef.field === 'type') {
        await this.toggleDialogType(cell.data.id, cell.data.type, cell.data.default)
      }
      this.gridOptions.api.refreshCells()
    },
    async onAddTest() {
      if (!this.HAS_ABTESTING_ACCESS) {
        return
      }
      await this.$vs.loading()

      const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId).get()
      const test = dialogRef.data()
      test.name = this.getNewVersionName()
      test.default = false
      test.enabled = false
      test.created = new Date()
      const testRef = await this.$db.collection('dialogs').doc(this.dialogId).collection('ab-tests').add(test)
      test.id = testRef.id
      test.testId = testRef.id
      test.dialogId = this.dialogId

      this.abtests.push(test)
      this.updateDialogAbTests({ dialogId: this.dialogId, abtests: this.abtests })
      await this.$vs.loading.close()
    },
    onDisplaySuccessMessage() {
      this.$vs.notify({
        time: 8800,
        title: this.$i18n.t('vue.success'),
        text: this.$i18n.t('vue.changesSavedSuccessfully'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success'
      })
    },
    async toggleStatus(id, status) {
      const vm = this
      await vm.$db.collection('dialogs').doc(vm.dialogId).collection('ab-tests').doc(id).set({ enabled: status }, { merge: true })
      vm.abtests.filter((x) => x.id === id)[0].enabled = status
      vm.updateDialogAbTests({ dialogId: vm.dialogId, abtests: vm.abtests })
      this.onDisplaySuccessMessage()
    },
    async updateTextField(id, is_default, field, text) {
      const vm = this
      const upt = {}
      upt[field] = text
      if (!is_default) {
        await vm.$db.collection('dialogs').doc(vm.dialogId).collection('ab-tests').doc(id).set(upt, { merge: true })
      } else {
        await vm.$db.collection('dialogs').doc(vm.dialogId).set(upt, { merge: true })
      }
      vm.abtests.filter((x) => x.id === id)[0][field] = text
      vm.updateDialogAbTests({ dialogId: vm.dialogId, abtests: vm.abtests })
      this.onDisplaySuccessMessage()
    },
    async updateDisplayOption(id, is_default, value) {
      const vm = this
      const upt = {}
      upt.showVideo = value.showVideo
      upt.videoURL = value.videoURL || null
      upt.isAlwaysShowButtonEnabled = value.isAlwaysShowButtonEnabled
      upt.isMinimizeSmallButtonEnabled = value.isMinimizeSmallButtonEnabled
      upt.isSmallButtonPreviewVisible = value.isSmallButtonPreviewVisible
      upt.showDefaultMedia = value.showDefaultMedia
      upt.useOneMediaForAllSteps = value.useOneMediaForAllSteps
      upt.useNewButtonPlusTextStyle = value.useNewButtonPlusTextStyle
      upt.buttonPlusTextNewMediaIconColor = value.buttonPlusTextNewMediaIconColor
      upt.allMedia = value.allMedia || null
      upt.buttonMedia = value.buttonMedia || null
      upt.smallButtonMedia = value.smallButtonMedia || null
      upt.welcomeScreenMedia = value.welcomeScreenMedia || null
      upt.buttonPlusTextNewMedia = value.buttonPlusTextNewMedia || null
      upt.smallButtonImage = value.smallButtonImage || null

      if (value.transcodedURL) {
        upt.transcodedURL = value.transcodedURL
      }
      upt.type = value.type
      upt.imageURL = value.imageURL || null
      upt.activateVideoSound = typeof value.activateVideoSound === 'boolean' ? value.activateVideoSound : null

      for (const prop in upt) {
        if (typeof upt[prop] === 'undefined') {
          upt[prop] = null
        }
      }

      if (!is_default) {
        await vm.$db.collection('dialogs').doc(vm.dialogId).collection('ab-tests').doc(id).set(upt, { merge: true })
      } else {
        await vm.$db.collection('dialogs').doc(vm.dialogId).set(upt, { merge: true })
      }

      vm.abtests.filter((x) => x.id === id)[0]['isAlwaysShowButtonEnabled'] = value.isAlwaysShowButtonEnabled
      vm.abtests.filter((x) => x.id === id)[0]['isMinimizeSmallButtonEnabled'] = value.isMinimizeSmallButtonEnabled
      vm.abtests.filter((x) => x.id === id)[0]['isSmallButtonPreviewVisible'] = value.isSmallButtonPreviewVisible
      vm.abtests.filter((x) => x.id === id)[0]['showDefaultMedia'] = value.showDefaultMedia
      vm.abtests.filter((x) => x.id === id)[0]['useOneMediaForAllSteps'] = value.useOneMediaForAllSteps
      vm.abtests.filter((x) => x.id === id)[0]['useNewButtonPlusTextStyle'] = value.useNewButtonPlusTextStyle
      vm.abtests.filter((x) => x.id === id)[0]['buttonPlusTextNewMediaIconColor'] = value.buttonPlusTextNewMediaIconColor
      vm.abtests.filter((x) => x.id === id)[0]['allMedia'] = value.allMedia || null
      vm.abtests.filter((x) => x.id === id)[0]['buttonMedia'] = value.buttonMedia || null
      vm.abtests.filter((x) => x.id === id)[0]['smallButtonMedia'] = value.smallButtonMedia || null
      vm.abtests.filter((x) => x.id === id)[0]['welcomeScreenMedia'] = value.welcomeScreenMedia || null
      vm.abtests.filter((x) => x.id === id)[0]['buttonPlusTextNewMedia'] = value.buttonPlusTextNewMedia || null
      vm.abtests.filter((x) => x.id === id)[0]['smallButtonImage'] = value.smallButtonImage || null
      vm.abtests.filter((x) => x.id === id)[0]['showVideo'] = value.showVideo
      vm.abtests.filter((x) => x.id === id)[0]['videoURL'] = value.videoURL || null
      vm.abtests.filter((x) => x.id === id)[0]['transcodedURL'] = value.transcodedURL || null
      vm.abtests.filter((x) => x.id === id)[0]['type'] = value.type
      vm.abtests.filter((x) => x.id === id)[0]['imageURL'] = value.imageURL || null
      vm.abtests.filter((x) => x.id === id)[0]['activateVideoSound'] = value.activateVideoSound || null
      vm.updateDialogAbTests({ dialogId: vm.dialogId, abtests: vm.abtests })
      this.onDisplaySuccessMessage()
    },
    async updateObject(id, is_default, field, value) {
      const vm = this
      const upt = {}
      upt[field] = value
      if (!is_default) {
        await vm.$db.collection('dialogs').doc(vm.dialogId).collection('ab-tests').doc(id).set(upt, { merge: true })
      } else {
        await vm.$db.collection('dialogs').doc(vm.dialogId).set(upt, { merge: true })
      }
      vm.abtests.filter((x) => x.id === id)[0][field] = value
      vm.updateDialogAbTests({ dialogId: vm.dialogId, abtests: vm.abtests })
      this.onDisplaySuccessMessage()
    },

    async updateWaitingRoomScreen(id, is_default, updatedFields) {
      const vm = this
      if (!is_default) {
        await vm.$db.collection('dialogs').doc(vm.dialogId).collection('ab-tests').doc(id).set(updatedFields, { merge: true })
      } else {
        await vm.$db.collection('dialogs').doc(vm.dialogId).set(updatedFields, { merge: true })
      }
      this.onDisplaySuccessMessage()
    },

    async updateEndScreen(id, is_default, updatedFields) {
      const vm = this
      if (!is_default) {
        await vm.$db.collection('dialogs').doc(vm.dialogId).collection('ab-tests').doc(id).set(updatedFields, { merge: true })
      } else {
        await vm.$db.collection('dialogs').doc(vm.dialogId).set(updatedFields, { merge: true })
      }
      this.onDisplaySuccessMessage()
    },

    async updateTextAndDialogType(id, is_default, updatedFields) {
      const vm = this
      if (!is_default) {
        await vm.$db.collection('dialogs').doc(vm.dialogId).collection('ab-tests').doc(id).set(updatedFields, { merge: true })
      } else {
        await vm.$db.collection('dialogs').doc(vm.dialogId).set(updatedFields, { merge: true })
      }
      this.onDisplaySuccessMessage()
    },
    async toggleDialogType(id, type, is_default) {
      type = type === '' ? 'button' : type === 'button' ? 'button-card' : 'button'
      const vm = this
      if (!is_default) {
        await vm.$db.collection('dialogs').doc(vm.dialogId).collection('ab-tests').doc(id).set({ type }, { merge: true })
      } else {
        await vm.$db.collection('dialogs').doc(vm.dialogId).set({ type }, { merge: true })
      }
      vm.abtests.filter((x) => x.id === id)[0].type = type
      vm.updateDialogAbTests({ dialogId: vm.dialogId, abtests: vm.abtests })
      this.onDisplaySuccessMessage()
    },

    getNewVersionName() {
      if (!this.abtests || this.abtests.length === 0) {
        return this.defaultVersionName
      }
      if (!this.abtests.find((x) => x.name === this.defaultVersionName)) {
        return this.defaultVersionName
      }
      for (let i = 1; ; i++) {
        const name = `${this.defaultVersionName} ${i}`
        if (!this.abtests.find((x) => x.name === name)) {
          return name
        }
      }
    },

    async getAllTests() {
      const vm = this
      await vm.$vs.loading()

      vm.tests = []
      vm.updateABTests([])

      const dialogRef = await vm.$db.collection('dialogs').doc(vm.dialogId).get()
      dialogRef.data()
      const defaultTest = dialogRef.data()
      const abTests = await vm.$db
        .collection('dialogs')
        .doc(vm.dialogId)
        .collection('ab-tests')
        .where('company', '==', vm.activeUserInfo.company)
        .orderBy('created', 'asc')
        .get()
      abTests.docs.forEach(async (doc) => {
        const test = doc.data()
        test.id = doc.id
        test.testId = doc.id
        test.dialogId = vm.dialogId
        const foundConversionRate = vm.conversionRates.find((x) => x.test_id === doc.id)
        test.conversion_rate = foundConversionRate ? `${foundConversionRate.conversion_rate} %` : `${0.0} %`
        if (test.default === true) {
          defaultTest.id = doc.id
          defaultTest.testId = doc.id
          defaultTest.conversion_rate = foundConversionRate ? `${foundConversionRate.conversion_rate} %` : `${0.0} %`
          defaultTest.dialogId = vm.dialogId
          defaultTest.enabled = test.enabled
          defaultTest.created = test.created
          defaultTest.default = test.default
          vm.tests.push(defaultTest)
        } else {
          test.default = false

          if (test) {
            if (!test.sloganTitle) {
              test.sloganTitle = "Let's Connect!"
            }

            if (typeof test.waitingRoomContentChatMessage === 'undefined') {
              test.waitingRoomContentChatMessage = {
                backgroundColor: '#C4C4C4',
                fontColor: '#12598d'
              }
            }

            if (typeof test.isAlwaysShowButtonEnabled === 'undefined') {
              test.isAlwaysShowButtonEnabled = false
            }

            if (typeof test.isMinimizeSmallButtonEnabled === 'undefined') {
              test.isMinimizeSmallButtonEnabled = false
            }

            if (typeof test.isSmallButtonPreviewVisible === 'undefined') {
              test.isSmallButtonPreviewVisible = false
            }

            if (typeof test.showDefaultMedia === 'undefined') {
              test.showDefaultMedia = true
            }

            if (typeof test.useOneMediaForAllSteps === 'undefined') {
              test.useOneMediaForAllSteps = false
            }

            if (typeof test.useNewButtonPlusTextStyle === 'undefined') {
              test.useNewButtonPlusTextStyle = false
            }

            if (typeof test.buttonPlusTextNewMediaIconColor === 'undefined') {
              test.buttonPlusTextNewMediaIconColor = '#3034F7'
            }

            if (typeof test.allMedia === 'undefined') {
              test.allMedia = null
            }

            if (typeof test.buttonMedia === 'undefined') {
              test.buttonMedia = null
            }

            if (typeof test.smallButtonMedia === 'undefined') {
              test.smallButtonMedia = null
            }

            if (typeof test.welcomeScreenMedia === 'undefined') {
              test.welcomeScreenMedia = null
            }

            if (typeof test.buttonPlusTextNewMedia === 'undefined') {
              test.buttonPlusTextNewMedia = null
            }

            if (typeof test.smallButtonImage === 'undefined') {
              test.smallButtonImage = this.defaultSmallButtonImage
            }
          }

          vm.tests.push(test)
        }
      })

      vm.updateABTests([...vm.tests])

      vm.updateDialogAbTests({ dialogId: vm.dialogId, abtests: [...vm.tests] })

      await vm.$vs.loading.close()
    }
  }
}
</script>

<style lang="scss">
.pa-bk-secondary {
  background: rgba(var(--vs-secondary), 1) !important;
  border-radius: 10px;
  color: #fff !important;
}
.ag-root {
  font-size: 0.85rem !important;
}
.default-dialog {
  background-color: rgba(0, 103, 240, 0.1) !important;
}
.cellrenderer-popover-action {
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 50px;

  &-button {
    top: 15px;
    width: 40px;
    height: 40px;
    z-index: 999;
    border-radius: 6px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.877);
    align-items: center;
  }

  &-button:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 1);
  }
}
</style>
<style scoped>
.dialog-container {
  position: relative;
}
.disabled {
  opacity: 0.2 !important;
}

.middle {
  transition: 0.5s ease;
  opacity: 0.2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.bottom-right {
  transition: 0.5s ease;
  opacity: 0.2;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-100%, -100%);
  -ms-transform: translate(-100%, -100%);
  text-align: center;
  width: 50%;
}
.dialog {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.dialog-container:hover .dialog {
  opacity: 0.4;
}
.dialog-container:hover .middle {
  opacity: 1;
}
.dialog-container:hover .bottom-right {
  opacity: 1;
}
</style>
<style lang="scss">
@import '../pages/dialog/configure-dialog.scss';
</style>
