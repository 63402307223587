<template>
  <div class="cellrenderer-popover-action flex justify-end items-center">
    <div class="cellrenderer-popover-action-button mr-2" @click="$emit('on-save-changes')">
      <check-icon color="#1C8439" :width="35" :height="35"></check-icon>
    </div>
    <div class="cellrenderer-popover-action-button ml-2" @click="$emit('on-cancel-changes')">
      <close-icon color="#F05541" :width="35" :height="35"></close-icon>
    </div>
  </div>
</template>
<script>
import CheckIcon from '../../../components/icons/CheckIcon.vue'
import CloseIcon from '../../../components/icons/CloseIcon.vue'
export default {
  components: {
    CheckIcon,
    CloseIcon
  }
}
</script>
