var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "portal",
        { attrs: { selector: "#portal-target" } },
        [
          _c(
            "connect-modal",
            {
              staticClass: "configure-dialog",
              attrs: {
                show: _vm.showPopup,
                width: "600px",
                bkcolor: "#F7F7F7",
              },
            },
            [
              _c(
                "div",
                { staticClass: "relative" },
                [
                  _c("save-changes", {
                    staticClass: "configure-dialog-save-changes",
                    on: {
                      "on-save-changes": _vm.onSaveChanges,
                      "on-cancel-changes": function ($event) {
                        _vm.showPopup = false
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "flex flex-row configure-dialog-modal-title",
                    },
                    [
                      _c("div", [
                        _c("h3", [
                          _vm._v(_vm._s(_vm.params.colDef.headerName)),
                        ]),
                      ]),
                    ]
                  ),
                  _vm.dialog && _vm.dialogId
                    ? _c("display-options", {
                        attrs: {
                          id: "crdo",
                          dialogId: _vm.dialogId,
                          infoImg: _vm.infoImg,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm.params.value === true
            ? _c("vs-button", {
                staticClass: "mr-2",
                attrs: {
                  color: "dark",
                  type: "line",
                  size: "large",
                  "icon-pack": "material-icons",
                  icon: "videocam",
                },
                on: { click: _vm.showDisplayOption },
              })
            : _c("vs-button", {
                staticClass: "mr-2",
                attrs: {
                  color: "dark",
                  type: "line",
                  size: "large",
                  "icon-pack": "material-icons",
                  icon: "photo_camera",
                },
                on: { click: _vm.showDisplayOption },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }