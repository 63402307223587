<template>
  <div>
    <vs-popup v-if="showPopup" :title="params.colDef.headerName" :active.sync="showPopup" class="cell-renderer-text abtest-popup">
      <div v-if="params.colDef.type === 'textColumn'">
        <vs-input
          v-if="params.colDef.field === 'name'"
          v-validate.immediate="`required|min:3|isValidABTestName:${JSON.stringify({ oldVal: params.value, abtests: abtestsNames })}`"
          data-vv-validate-on="blur"
          :label-placeholder="params.colDef.headerName"
          name="name"
          :placeholder="params.colDef.headerName"
          v-model="text"
          class="w-full mt-6 p-1"
        />
        <vs-input
          v-else
          data-vv-validate-on="blur"
          :label-placeholder="params.colDef.headerName"
          :name="params.colDef.field"
          :placeholder="params.colDef.headerName"
          v-model="text"
          class="w-full mt-6 p-1"
        />
      </div>

      <vs-textarea
        v-if="params.colDef.type === 'textAreaColumn'"
        v-validate="'required'"
        data-vv-validate-on="blur"
        :label-placeholder="params.colDef.headerName"
        :name="params.colDef.field"
        :placeholder="params.colDef.headerName"
        v-model="text"
        class="w-full mt-6 p-1"
      />
      <vs-input-number
        class="no-margin"
        v-validate="'required'"
        :name="params.colDef.field"
        data-vv-validate-on="blur"
        v-if="params.colDef.type === 'numberColumn'"
        :min="1"
        :max="120"
        v-model="text"
      />
      <span class="w-full text-danger text-sm ml-2" v-if="errors.first(params.colDef.field)">{{ errors.first(params.colDef.field) }}</span>
      <br />
      <div class="vs-input p-1">
        <div class="vs-con-input">
          <vs-button class="mt-3 mb-6" type="line" @click="saveChanges" :disabled="!validateForm">{{ $t('vue.save') }}</vs-button>
        </div>
      </div>
    </vs-popup>
    <div style="cursor: pointer" @click="displayPopup">
      {{ params.value }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CellRendererText',
  data() {
    return {
      text: '',
      showPopup: false
    }
  },

  computed: {
    ...mapGetters({
      abtests: 'abtests'
    }),
    abtestsNames() {
      if (!this.abtests) {
        return []
      }
      return this.abtests.map((x) => {
        return x.name
      })
    },
    validateForm() {
      return !this.errors.any() && this.text !== ''
    }
  },
  mounted() {
    this.text = this.params.value
  },
  methods: {
    displayPopup() {
      this.text = this.params.value
      this.showPopup = true
    },
    saveChanges() {
      try {
        const vm = this
        if (!vm.validateForm) return

        vm.$serverBus.$emit('ab-testing-update-text', {
          newValue: vm.params.colDef.type === 'numberColumn' ? parseInt(vm.text) : vm.text,
          oldValue: vm.params.value,
          field: vm.params.colDef.field,
          data: vm.params.data
        })

        vm.showPopup = false
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      }
    }
  }
}
</script>

<style>
.cell-renderer-text .vs-popup {
  width: 400px !important;
  height: auto !important;
}
.no-margin {
  margin: 0px;
}
</style>
