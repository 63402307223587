var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "portal",
        { attrs: { selector: "#portal-target" } },
        [
          _c(
            "connect-modal",
            {
              staticClass: "configure-dialog",
              attrs: {
                show: _vm.showPopup,
                width: "calc(70vw)",
                bkcolor: "#F7F7F7",
              },
            },
            [
              _c("scroll-view", { staticStyle: { height: "calc(85vh)" } }, [
                _c(
                  "div",
                  { staticClass: "relative" },
                  [
                    _c("save-changes", {
                      staticClass: "configure-dialog-save-changes",
                      on: {
                        "on-save-changes": _vm.onSaveChanges,
                        "on-cancel-changes": function ($event) {
                          _vm.showPopup = false
                        },
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-row configure-dialog-modal-title",
                      },
                      [
                        _c("div", [
                          _c("h3", [
                            _vm._v(_vm._s(_vm.params.colDef.headerName)),
                          ]),
                        ]),
                      ]
                    ),
                    _vm.dialog && _vm.dialogId
                      ? _c("welcome-message", {
                          attrs: {
                            id: "cell-renderer",
                            infoImg: _vm.infoImg,
                            dialogId: _vm.dialogId,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("vs-button", {
            staticClass: "mr-2",
            attrs: {
              color: "dark",
              type: "line",
              size: "large",
              "icon-pack": "material-icons",
              icon: "schedule",
            },
            on: { click: _vm.showWaitingRoom },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }