<template>
  <div>
    <portal selector="#portal-target">
      <connect-modal :show="showPopup" width="calc(70vw)" bkcolor="#F7F7F7" class="configure-dialog">
        <scroll-view style="height: calc(85vh)">
          <div class="relative">
            <save-changes @on-save-changes="onSaveChanges" @on-cancel-changes="showPopup = false" class="configure-dialog-save-changes"></save-changes>
            <div class="flex flex-row configure-dialog-modal-title">
              <div>
                <h3>{{ params.colDef.headerName }}</h3>
              </div>
            </div>
            <welcome-message id="cell-renderer" v-if="dialog && dialogId" :infoImg="infoImg" :dialogId="dialogId"></welcome-message>
          </div>
        </scroll-view>
      </connect-modal>
    </portal>
    <div>
      <vs-button class="mr-2" color="dark" type="line" size="large" icon-pack="material-icons" icon="schedule" @click="showWaitingRoom"></vs-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Portal } from '@linusborg/vue-simple-portal'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import ConnectModal from '../../../components/ConnectModal.vue'
import WelcomeMessage from '../../../components/dialog/WelcomeMessage.vue'
import SaveChanges from './SaveChanges.vue'
export default {
  components: {
    ScrollView,
    Portal,
    ConnectModal,
    WelcomeMessage,
    SaveChanges
  },
  data() {
    return {
      showPopup: false,
      dialogId: null,
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog'
    })
  },
  methods: {
    translate(code) {
      return this.$i18n.t(code)
    },
    onSaveChanges() {
      try {
        this.$serverBus.$emit('ab-testing-update-waiting-room', {
          field: this.params.colDef.field,
          data: this.params.data,
          newValue: this.dialog
        })
        this.showPopup = false
      } catch (err) {
        /* eslint-disable no-console */
        console.log(err)
      }
    },
    async showWaitingRoom() {
      const dialog = { ...this.params.data }
      if (!dialog.waitingRoomMessage) {
        dialog.waitingRoomMessage = this.translate('vue.waitingRoomDefaultMessage')
      }
      this.dialogId = dialog.dialogId
      if (!dialog.default) {
        const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId).collection('ab-tests').doc(dialog.testId).get()
        if (dialogRef && dialogRef.data()) {
          this.$store.commit('UPDATE_DIALOG', {
            ...dialog,
            ...dialogRef.data()
          })
        }
      } else {
        const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId).get()
        if (dialogRef && dialogRef.data()) {
          this.$store.commit('UPDATE_DIALOG', {
            ...dialog,
            ...dialogRef.data()
          })
        }
      }
      this.showPopup = true
    }
  }
}
</script>
<style lang="scss">
@import '../../pages/dialog/configure-dialog.scss';
</style>
