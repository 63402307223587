var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cellrenderer-popover-action flex justify-end items-center",
    },
    [
      _c(
        "div",
        {
          staticClass: "cellrenderer-popover-action-button mr-2",
          on: {
            click: function ($event) {
              return _vm.$emit("on-save-changes")
            },
          },
        },
        [
          _c("check-icon", {
            attrs: { color: "#1C8439", width: 35, height: 35 },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "cellrenderer-popover-action-button ml-2",
          on: {
            click: function ($event) {
              return _vm.$emit("on-cancel-changes")
            },
          },
        },
        [
          _c("close-icon", {
            attrs: { color: "#F05541", width: 35, height: 35 },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }