var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex flex-col",
      style: { direction: _vm.$vs.rtl ? "rtl" : "ltr" },
    },
    [
      _vm.params.data.default
        ? _c(
            "div",
            { staticClass: "default-row-title" },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.standardABTestTitle"))),
              ]),
              _c(
                "vx-tooltip",
                {
                  staticClass: "inline-flex",
                  attrs: {
                    text: _vm.$t("info.abDefaultTestInfo"),
                    position: "top",
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "feather feather-info",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "20",
                        height: "20",
                        viewBox: "0 0 24 24",
                        fill: "none",
                        stroke: "#12598D",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                      },
                    },
                    [
                      _c("circle", { attrs: { cx: "12", cy: "12", r: "10" } }),
                      _c("line", {
                        attrs: { x1: "12", y1: "16", x2: "12", y2: "12" },
                      }),
                      _c("line", {
                        attrs: { x1: "12", y1: "8", x2: "12.01", y2: "8" },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c(
            "vs-prompt",
            {
              attrs: {
                title: _vm.$t("modals.deleteWebConnect"),
                color: "danger",
                "cancel-text": _vm.$t("vue.cancel"),
                "accept-text": _vm.$t("vue.delete"),
                active: _vm.confirmDeleteTest,
              },
              on: {
                cancel: _vm.onCloseModal,
                accept: _vm.deleteTest,
                close: _vm.onCloseModal,
                "update:active": function ($event) {
                  _vm.confirmDeleteTest = $event
                },
              },
            },
            [
              _vm.params.data.name
                ? _c("div", { staticClass: "con-exemple-prompt" }, [
                    _c("p", [
                      _c("strong", [_vm._v(_vm._s(_vm.params.data.name))]),
                    ]),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("modals.deleteWebConnectQuestion")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "vx-tooltip",
            { attrs: { text: _vm.$t("info.previewDialog"), position: "top" } },
            [
              _c("vs-button", {
                staticClass: "mr-2",
                attrs: {
                  color: "dark",
                  type: "line",
                  size: "large",
                  "icon-pack": "feather",
                  icon: "icon-eye",
                  disabled: !_vm.HAS_ABTESTING_ACCESS,
                },
                on: {
                  click: function ($event) {
                    return _vm.preview()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            {
              attrs: { text: _vm.$t("info.duplicateDialog"), position: "top" },
            },
            [
              _c("vs-button", {
                staticClass: "mr-2",
                attrs: {
                  color: "dark",
                  type: "line",
                  size: "large",
                  "icon-pack": "feather",
                  icon: "icon-copy",
                  disabled: !_vm.HAS_ABTESTING_ACCESS,
                },
                on: {
                  click: function ($event) {
                    return _vm.duplicate()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { text: _vm.$t("info.enableDialog"), position: "top" } },
            [
              !_vm.params.data.enabled
                ? _c("vs-button", {
                    staticClass: "mr-2",
                    attrs: {
                      color: "danger",
                      type: "line",
                      size: "large",
                      "icon-pack": "feather",
                      icon: "icon-toggle-left",
                      disabled: !_vm.HAS_ABTESTING_ACCESS,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeStatus()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { text: _vm.$t("info.disableDialog"), position: "top" } },
            [
              _vm.params.data.enabled
                ? _c("vs-button", {
                    staticClass: "mr-2",
                    attrs: {
                      color: "success",
                      type: "line",
                      size: "large",
                      "icon-pack": "feather",
                      icon: "icon-toggle-right",
                      disabled: !_vm.HAS_ABTESTING_ACCESS,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeStatus()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { text: _vm.$t("info.deleteDialog"), position: "top" } },
            [
              _c("vs-button", {
                staticClass: "mr-2",
                attrs: {
                  color: "danger",
                  type: "line",
                  size: "large",
                  "icon-pack": "feather",
                  icon: "icon-trash",
                  disabled:
                    _vm.params.data.default || !_vm.HAS_ABTESTING_ACCESS,
                },
                on: {
                  click: function ($event) {
                    _vm.confirmDeleteTest = true
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }