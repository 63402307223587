var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showPopup
        ? _c(
            "vs-popup",
            {
              staticClass: "cell-renderer-text abtest-popup",
              attrs: {
                title: _vm.params.colDef.headerName,
                active: _vm.showPopup,
              },
              on: {
                "update:active": function ($event) {
                  _vm.showPopup = $event
                },
              },
            },
            [
              _vm.params.colDef.type === "textColumn"
                ? _c(
                    "div",
                    [
                      _vm.params.colDef.field === "name"
                        ? _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value:
                                  "required|min:3|isValidABTestName:" +
                                  JSON.stringify({
                                    oldVal: _vm.params.value,
                                    abtests: _vm.abtestsNames,
                                  }),
                                expression:
                                  "`required|min:3|isValidABTestName:${JSON.stringify({ oldVal: params.value, abtests: abtestsNames })}`",
                                modifiers: { immediate: true },
                              },
                            ],
                            staticClass: "w-full mt-6 p-1",
                            attrs: {
                              "data-vv-validate-on": "blur",
                              "label-placeholder": _vm.params.colDef.headerName,
                              name: "name",
                              placeholder: _vm.params.colDef.headerName,
                            },
                            model: {
                              value: _vm.text,
                              callback: function ($$v) {
                                _vm.text = $$v
                              },
                              expression: "text",
                            },
                          })
                        : _c("vs-input", {
                            staticClass: "w-full mt-6 p-1",
                            attrs: {
                              "data-vv-validate-on": "blur",
                              "label-placeholder": _vm.params.colDef.headerName,
                              name: _vm.params.colDef.field,
                              placeholder: _vm.params.colDef.headerName,
                            },
                            model: {
                              value: _vm.text,
                              callback: function ($$v) {
                                _vm.text = $$v
                              },
                              expression: "text",
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.params.colDef.type === "textAreaColumn"
                ? _c("vs-textarea", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "w-full mt-6 p-1",
                    attrs: {
                      "data-vv-validate-on": "blur",
                      "label-placeholder": _vm.params.colDef.headerName,
                      name: _vm.params.colDef.field,
                      placeholder: _vm.params.colDef.headerName,
                    },
                    model: {
                      value: _vm.text,
                      callback: function ($$v) {
                        _vm.text = $$v
                      },
                      expression: "text",
                    },
                  })
                : _vm._e(),
              _vm.params.colDef.type === "numberColumn"
                ? _c("vs-input-number", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "no-margin",
                    attrs: {
                      name: _vm.params.colDef.field,
                      "data-vv-validate-on": "blur",
                      min: 1,
                      max: 120,
                    },
                    model: {
                      value: _vm.text,
                      callback: function ($$v) {
                        _vm.text = $$v
                      },
                      expression: "text",
                    },
                  })
                : _vm._e(),
              _vm.errors.first(_vm.params.colDef.field)
                ? _c(
                    "span",
                    { staticClass: "w-full text-danger text-sm ml-2" },
                    [_vm._v(_vm._s(_vm.errors.first(_vm.params.colDef.field)))]
                  )
                : _vm._e(),
              _c("br"),
              _c("div", { staticClass: "vs-input p-1" }, [
                _c(
                  "div",
                  { staticClass: "vs-con-input" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "mt-3 mb-6",
                        attrs: { type: "line", disabled: !_vm.validateForm },
                        on: { click: _vm.saveChanges },
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.save")))]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { cursor: "pointer" }, on: { click: _vm.displayPopup } },
        [_vm._v("\n    " + _vm._s(_vm.params.value) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }