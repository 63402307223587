<template>
  <div class="flex flex-col" :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <div v-if="params.data.default" class="default-row-title">
      <span class="mr-2">{{ $t('vue.standardABTestTitle') }}</span>
      <vx-tooltip :text="$t('info.abDefaultTestInfo')" position="top" class="inline-flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#12598D"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-info"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="16" x2="12" y2="12"></line>
          <line x1="12" y1="8" x2="12.01" y2="8"></line>
        </svg>
      </vx-tooltip>
    </div>

    <div class="flex">
      <vs-prompt
        :title="$t('modals.deleteWebConnect')"
        @cancel="onCloseModal"
        @accept="deleteTest"
        @close="onCloseModal"
        color="danger"
        :cancel-text="$t('vue.cancel')"
        :accept-text="$t('vue.delete')"
        :active.sync="confirmDeleteTest"
      >
        <div class="con-exemple-prompt" v-if="params.data.name">
          <p>
            <strong>{{ params.data.name }}</strong>
          </p>
          {{ $t('modals.deleteWebConnectQuestion') }}
        </div>
      </vs-prompt>
      <vx-tooltip :text="$t('info.previewDialog')" position="top">
        <vs-button
          class="mr-2"
          color="dark"
          type="line"
          size="large"
          icon-pack="feather"
          icon="icon-eye"
          :disabled="!HAS_ABTESTING_ACCESS"
          @click="preview()"
        ></vs-button>
      </vx-tooltip>
      <vx-tooltip :text="$t('info.duplicateDialog')" position="top">
        <vs-button
          class="mr-2"
          color="dark"
          type="line"
          size="large"
          icon-pack="feather"
          icon="icon-copy"
          :disabled="!HAS_ABTESTING_ACCESS"
          @click="duplicate()"
        ></vs-button>
      </vx-tooltip>
      <vx-tooltip :text="$t('info.enableDialog')" position="top">
        <vs-button
          class="mr-2"
          color="danger"
          type="line"
          size="large"
          icon-pack="feather"
          icon="icon-toggle-left"
          :disabled="!HAS_ABTESTING_ACCESS"
          v-if="!params.data.enabled"
          @click="changeStatus()"
        ></vs-button>
      </vx-tooltip>
      <vx-tooltip :text="$t('info.disableDialog')" position="top">
        <vs-button
          class="mr-2"
          color="success"
          type="line"
          size="large"
          icon-pack="feather"
          icon="icon-toggle-right"
          :disabled="!HAS_ABTESTING_ACCESS"
          v-if="params.data.enabled"
          @click="changeStatus()"
        ></vs-button>
      </vx-tooltip>
      <vx-tooltip :text="$t('info.deleteDialog')" position="top">
        <vs-button
          class="mr-2"
          color="danger"
          type="line"
          size="large"
          icon-pack="feather"
          icon="icon-trash"
          :disabled="params.data.default || !HAS_ABTESTING_ACCESS"
          @click="confirmDeleteTest = true"
        ></vs-button>
      </vx-tooltip>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

import { mapGetters } from 'vuex'

export default {
  name: 'CellRendererActions',
  data() {
    return {
      confirmDeleteTest: false,
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser'
    }),
    HAS_ABTESTING_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('ab-testing') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    }
  },
  methods: {
    onCloseModal() {
      this.confirmDeleteTest = false
    },
    preview() {
      this.$serverBus.$emit('ab-testing-preview', this.params.value)
    },
    deleteTest() {
      if (!this.HAS_ABTESTING_ACCESS) {
        return
      }
      this.$serverBus.$emit('ab-testing-delete-test', this.params.value)
      this.confirmDeleteTest = false
    },
    duplicate() {
      if (!this.HAS_ABTESTING_ACCESS) {
        return
      }
      this.$serverBus.$emit('ab-testing-duplicate', this.params.value)
    },

    changeStatus() {
      if (!this.HAS_ABTESTING_ACCESS) {
        return
      }
      this.$serverBus.$emit('ab-testing-change-status', this.params.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.default-row-title {
  color: #12598d;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
}
</style>
