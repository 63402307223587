var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.params && _vm.params.value && _vm.params.value.length > 0
    ? _c(
        "vs-chip",
        {
          staticClass: "ag-grid-cell-chip",
          attrs: { color: _vm.chipColor(_vm.params.value) },
        },
        [_c("span", [_vm._v(_vm._s(_vm.dialogType(_vm.params.value)))])]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }