<template>
  <div>
    <portal selector="#portal-target">
      <connect-modal :show="showPopup" width="calc(95vw)" bkcolor="#F7F7F7" class="configure-dialog">
        <scroll-view style="height: calc(95vh)">
          <div class="relative">
            <save-changes @on-save-changes="onSaveChanges" @on-cancel-changes="showPopup = false" class="configure-dialog-save-changes"></save-changes>
            <div class="flex flex-row configure-dialog-modal-title">
              <div>
                <h3>{{ params.colDef.headerName }}</h3>
              </div>
            </div>

            <text-and-dialog-type v-if="dialog && dialogId" :infoImg="infoImg" :dialogId="dialogId" :videoKey="videoKey"></text-and-dialog-type>
          </div>
        </scroll-view>
      </connect-modal>
    </portal>
    <div>
      <vs-button class="mr-2" color="dark" type="line" size="large" icon-pack="material-icons" icon="image" @click="showTextAndDialogType"></vs-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Portal } from '@linusborg/vue-simple-portal'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import ConnectModal from '../../../components/ConnectModal.vue'
import TextAndDialogType from '../../../components/dialog/TextAndDialogType.vue'
import SaveChanges from './SaveChanges.vue'
export default {
  components: {
    ScrollView,
    Portal,
    ConnectModal,
    TextAndDialogType,
    SaveChanges
  },
  data() {
    return {
      showPopup: false,
      infoImg: require('@/assets/images/elements/info.svg'),
      dialogId: null,
      videoKey: Math.random().toString(36).substring(2, 15)
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog'
    })
  },
  methods: {
    translate(code) {
      return this.$i18n.t(code)
    },
    onSaveChanges() {
      this.$serverBus.$emit('ab-testing-update-text-dialog-type', {
        field: this.params.colDef.field,
        data: this.params.data,
        newValue: this.dialog
      })
      this.showPopup = false
    },
    showTextAndDialogType() {
      const dialog = { ...this.params.data }
      if (typeof dialog.isMoreInformationEnabled === 'undefined') {
        dialog.isMoreInformationEnabled = false
      }
      if (!dialog.textStep2) {
        dialog.textStep2 = this.translate('vue.textStep2DefaultText')
      }
      if (!dialog.step2ButtonColor) {
        dialog.step2ButtonColor = '#3B86F7'
      }
      if (!dialog.step2TextColor) {
        dialog.step2TextColor = '#ffffff'
      }
      if (!dialog.step2IconColor) {
        dialog.step2IconColor = '#ffffff'
      }

      if (!dialog.buttonPlusTextNewMediaIconColor) {
        dialog.buttonPlusTextNewMediaIconColor = '#3034F7'
      }

      this.$store.commit('UPDATE_DIALOG', dialog)
      this.dialogId = dialog.dialogId
      this.videoKey = Math.random().toString(36).substring(2, 15)
      this.showPopup = true
    }
  }
}
</script>
<style lang="scss">
@import '../../pages/dialog/configure-dialog.scss';
</style>
