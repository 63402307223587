var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "vs-popup",
        {
          staticClass: "cell-renderer-color abtest-popup",
          attrs: { title: _vm.params.colDef.headerName, active: _vm.showPopup },
          on: {
            "update:active": function ($event) {
              _vm.showPopup = $event
            },
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.color,
                expression: "color",
              },
            ],
            staticClass: "pa-color-picker w-full",
            attrs: { type: "color", name: _vm.params.colDef.field },
            domProps: { value: _vm.color },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.color = $event.target.value
              },
            },
          }),
          _c("br"),
          _c(
            "vs-button",
            {
              staticClass: "mt-3 mb-6",
              attrs: { type: "line" },
              on: { click: _vm.saveChanges },
            },
            [_vm._v(_vm._s(_vm.$t("vue.save")))]
          ),
        ],
        1
      ),
      _vm.params.colDef.type === "backgroundColorColum"
        ? _c(
            "vs-button",
            {
              attrs: {
                size: "small",
                color: _vm.colourNameToHex(_vm.params.value),
              },
              on: { click: _vm.displayPopup },
            },
            [
              _c(
                "span",
                {
                  style:
                    "color: " +
                    _vm.params.data.confirmButtonTextColor +
                    "; font-weight: bold;",
                },
                [_vm._v(_vm._s(_vm.$t("vue.changeColor")))]
              ),
            ]
          )
        : _vm._e(),
      _vm.params.colDef.type === "textColorColumn"
        ? _c(
            "vs-button",
            {
              attrs: {
                size: "small",
                color: _vm.colourNameToHex(_vm.params.data.confirmButtonColor),
              },
              on: { click: _vm.displayPopup },
            },
            [
              _c(
                "span",
                {
                  style:
                    "color: " +
                    _vm.colourNameToHex(_vm.params.value) +
                    "; font-weight: bold;",
                },
                [_vm._v(_vm._s(_vm.$t("vue.changeColor")))]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }