<template>
  <div class="configure-dialog-step-four">
    <div class="flex-box-column-start">
      <div class="vx-row w-full">
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="headingfont mr-2">{{ $t('vue.welcomeMessageSetupHeader') }}</div>
          <vx-tooltip :text="$t('info.welcomeMessageSetupHeader')" position="top">
            <img :src="infoImg" />
          </vx-tooltip>
        </vs-col>
      </div>
    </div>
    <div class="mt-4 flex w-full">
      <div class="mb-2">{{ $t('vue.welcomeMessageLabel') }}</div>
    </div>
    <div class="flex-view">
      <div class="mr-5 flex flex-column">
        <div>
          <quill-editor :value="dialog.waitingRoomMessage" @input="updateWaitingRoomMessage" ref="quillEditorA" :options="editorOption"> </quill-editor>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor
  },
  props: {
    infoImg: {
      type: String,
      required: true
    },
    dialogId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [['bold', 'italic', 'underline']]
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog'
    })
  },
  methods: {
    updateWaitingRoomMessage(e) {
      this.$store.commit('UPDATE_DIALOG', {
        ...this.dialog,
        waitingRoomMessage: e
      })
    }
  }
}
</script>

<style lang="scss">
.configure-dialog-step-four {
  .flex-view {
    display: flex;

    @media only screen and (max-width: 769px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    @media only screen and (min-width: 770px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .ql-editor {
    min-height: 200px;
    font-family: 'Lato';
  }
}
</style>
